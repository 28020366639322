.messageModal {
    .ant-modal-content {
        border-radius: 10px;

        .ant-modal-header {
            // padding: 10px 15px;
            // border-radius: 10px 10px 0 0;

            .ant-modal-title {
                font-size: 16px;
                font-weight: 400;
                color: #313131;
            }
        }

        // .ant-modal-close-x {
        //     width: 43px;
        //     height: 43px;
        //     line-height: 43px;
        // }

        .ant-modal-body {
            padding: 20px 0;

        }
    }

    .messageModalContent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-height: 70vh;
        overflow-y: auto;
        padding: 0 20px;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            border-radius: 4px;

            &:hover {
                background-color: #a8a8a8;
            }

            &:active {
                background-color: #787878;
            }
        }

        & {
            scrollbar-width: thin;
            scrollbar-color: #c1c1c1 #eee;
        }

        .iconBox {
            font-size: 34px;
            color: red;
        }

        .descriptionBox {
            font-size: 16px;
            font-weight: 400;
            color: #535353;
            text-align: center;
            word-break: break-all;
        }

        .messageBox {
            font-size: 14px;
            font-weight: normal;
            color: #9E9E9E;
            margin: 10px 0;
            text-align: center;
        }

        .infoTextBox {
            width: 100%;
            font-size: 14px;
            font-weight: normal;
            color: #9E9E9E;
            margin: 10px 0;
            text-align: left
        }

        .btnBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            margin: 20px 0 10px 0;
            width: 100%;

            .ant-btn {
                margin-left: 0;

                &:first-of-type {
                    margin-right: 20px;
                }

                &:hover {
                    border-color: #0068B2;
                }
            }
        }
    }
}