.licenseManagementContent {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff;
    padding: 20px;

    .filterItemsBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .filterItemsContent {
            display: flex;
            align-items: center;

            .filterItem {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;

                >span {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #313131;
                    margin-right: 12px;
                }
            }
        }
    }

    .tableContent {
        margin-top: 24px;

        .selectItem {
            td {
                background: #f2f6fa !important;
            }
        }
        
        .tableTagsBox {
            width: 100%;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            svg {
                margin-right: 4px;
                width: 14px;
                height: 14px;
                flex: none;
            }

            svg:nth-last-of-type(1) {
                margin-right: 0px;
            }
        }
    }
}