.editHtmlContent {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff;
    padding: 20px;
    position: relative;

    .ant-spin-nested-loading {
        height: 100%;
        width: 100%;
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }

    .verSelect {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .itemTitle {
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        margin-bottom: 16px;

        span {
            display: block;
            width: 2px;
            height: 14px;
            background: linear-gradient(223deg, #006BB3 0%, #15D2D6 100%);
            margin-right: 8px;
        }
    }

    .itemTitle2 {
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 14px;
        padding: 0 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        margin-bottom: 16px;

        span {
            font-size: 20px;
            font-weight: bold;
            color: #0068B2;
            margin-right: 10px;
            position: relative;

            i {
                position: absolute;
                bottom: 0px;
                left: 18px;
                display: block;
                width: 40%;
                height: 70%;
                background: #fff;
                border-left: #787878 1px solid;
                transform: rotate(45deg);
            }

        }

    }

    .subscriptionItemsContent {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 10px;

        .subscriptionItem {
            display: flex;
            align-items: center;
            width: 50%;
            margin-bottom: 16px;
            height: 32px;
            color: #313131;

            i {
                display: flex;
                width: 12px;
                height: 100%;
                padding: 0 2px;
                align-items: center;
                justify-content: center;
                color: #FE545F;
            }

            // i::after {
            //     content: '*';
            //     color: #FE545F;
            //     height: 12px;
            // }

            .itemLabel {
                display: inline-block;
                width: 60px;
                margin-right: 20px;
                text-align: justify;
                text-align-last: justify;
            }
        }
    }

    .subscriptionLicenseItemsContent {
        width: 100%;
        padding: 0 10px;

        .subscriptionLicenseItemsHeader {
            display: flex;
            width: 100%;
            height: 62px;
            align-items: center;
            background: rgba(0, 104, 178, 0.05);

            .headerItem {
                height: 100%;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding: 0 8px;
                color: #535353;

                i {
                    display: flex;
                    height: 100%;
                    padding: 0 2px;
                    align-items: center;
                    justify-content: center;
                    color: #FE545F;
                }
            }

            .addBox {
                width: 14px;
            }
        }

        .subscriptionLicenseItemLine {
            display: flex;
            width: 100%;
            height: 62px;
            align-items: center;
            border-bottom: 1px solid rgba(0, 104, 178, 0.1);

            .itemBox {
                height: 100%;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding: 0 8px;
                color: #535353;

                .formItem {
                    width: 100%;
                    position: relative;

                    .errorBox {
                        position: absolute;
                        bottom: -16px;
                        left: 0;
                        color: #FE545F;
                        font-size: 12px;
                    }
                }
            }

            .deleteIcon {
                width: 14px;
                color: #FE545F;
                cursor: pointer;
            }
        }

        .subscriptionLicenseEmptyItem {
            display: flex;
            width: 100%;
            height: 62px;
            align-items: center;
            justify-content: center;
            color: #535353;
            border-bottom: 1px solid rgba(0, 104, 178, 0.1);
        }

        .subscriptionLicenseAddIcon {
            width: 100px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #0068B2;
            margin-top: 24px;
            cursor: pointer;
        }
    }

    .subscriptionLicenseBtnBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        .ant-btn {
            height: 40px;
            width: 180px;
        }
    }

    .moreSelectDiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .funcDiv,
        .menuDiv {
            width: calc(50% - 5px);
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .typeDiv {
                flex: none;
            }

            .countDiv {
                flex: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border: 1px solid #0068B2;
                font-size: 12px;
                font-family: Arial, Arial;
                font-weight: 700;
                color: #0068B2;
                border-radius: 50%;
                margin-left: 5px;
            }

            span {
                display: flex;
                height: 21px;
                align-items: center;
                justify-content: center;
                flex: none;
                background: rgba(108, 174, 255, 0.2);
                border-radius: 20px;
                opacity: 1;
                border: 1px solid #6CAEFF;
                padding: 1px 4px;
                font-size: 12px;
                color: #6CAEFF;
                margin-left: 5px;
            }
        }
    }

    .hasFunValue {
        .ant-select-selector::before {
            content: '功能';
            font-size: 12px;
            margin-right: 5px;
        }
    }

    .hasMenuValue {
        .ant-select-selector::before {
            content: '菜单';
            font-size: 12px;
            margin-right: 5px;
        }
    }

    .moreSelectTag {
        font-size: 12px;
        background: rgba(108, 174, 255, 0.2);
        border-radius: 20px;
        opacity: 1;
        border: 1px solid #6CAEFF;
        color: #6CAEFF;
        padding: 1px 5px;
        margin-right: 5px;
    }

    .ant-select-selection-overflow-item-rest .ant-select-selection-item {
        width: 22px;
        height: 22px;
        background: #fff;
        font-size: 12px;
        font-family: Arial, Arial;
        font-weight: 700;
        color: #0068B2;
        border-radius: 50%;
        padding: 0;

        // margin-left: 5px;
        .ant-select-selection-item-content {
            width: 100%;
            height: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .moreSelectOmittedTag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        opacity: 1;
        border: 1px solid #0068B2;
        font-size: 12px;
        font-family: Arial, Arial;
        font-weight: 700;
        color: #0068B2;
        border-radius: 50%;
    }
}