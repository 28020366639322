.creatProjectDrawer {
    border-radius: 10px 0px 0px 10px;

    .ant-drawer-body {
        // padding-bottom: 5px;
        // padding-top: 20px;
        padding: 0px
    }

    .userTable {

        .ant-table-thead {
            .ant-table-cell {
                padding: 0;
                height: 30px;
                background: #fff;
                border-bottom: none;
                color: rgba(0, 0, 0, 0.25);
                font-weight: 400;

                &::before {
                    background-color: #fff !important
                }
            }
        }

        .ant-table-cell {
            height: 30px;
            padding: 6px;
        }

        .ant-table-row {
            border-bottom: none !important;
        }

        .ant-table-tbody>tr>td {
            border-bottom: none !important;
        }

        .ant-select-selector {
            padding: 0;
        }
    }

    .optionUserTag {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 5px;

        .ant-avatar {
            width: 20px !important;
            height: 20px !important;
        }
    }

    .creatProjectForm {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px;
        padding-bottom: 0;

        .userListBox {
            .searchBox {
                display: flex;
                justify-content: space-between;

                // margin-bottom: 20px;
                .ant-btn-primary {
                    border-color: #0068B2;
                    background: #0068B2;
                }

                .ant-btn-primary:hover {
                    border-color: #0068b2df;
                    background: #0068b2df;
                }
            }
        }

        .contentMap {
            height: 100%;
            margin-top: 10px;
        }
    }

    .deleteIcon {
        cursor: pointer;
        z-index: 999;
        &:hover {
            svg {
                fill: #0068B2;
            }
        }

        svg {
            width: 16px;
            height: 16px;
            fill: #535353;
        }
    }

    .drawerFooterBtnBox {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .ant-btn {
            min-width: 90px;
            margin-left: 10px;
            // color: #fff;
            // background: #016DB4;
            // border-color: #0068B2;
        }

        .noBg {
            // color: #535353;
            // background: #FFFFFF;
            // border-color: #D9D9D9;
        }
    }
}

.userSelectItem2 {
    .name {
        font-weight: bold;
    }

    .labelBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
            &:first-of-type {
                width: 130px;
            }

            &:last-of-type {
                width: calc(100% - 130px)
            }

            display: inline-block;
            // width: 50%;
            font-weight: 400;
            font-size: 12px;
            color: #535353;
        }
    }
}