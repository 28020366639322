.App {
  // text-align: center;
  height: 100%;
  width: 100%;

  .my-layout {
    height: 100%;
    width: 100%;
  }

  .my-content {
    // padding: 20px;
    // padding-top: 0px;
    background-color: #f7f7f7;
    position: relative;

    .breadcrumbBox {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 9;
      display: flex;
      // background: #535353;
      height: 24px;
      width: 100%;
      padding: 0 24px;
      align-items: center;

      .ant-breadcrumb {
        font-size: 12px;
      }

      .backSpan {
        cursor: pointer;
      }

      .backSpan:hover {
        color: #0068B2;
      }
    }

    .contentBox {
      height: 100%;
      width: 100%;
      // background: #fff;
      padding: 24px;
      border-radius: 5px;
    }

    .scrollbars {
      border-radius: 5px;
      // background: #fff;
    }
  }

  .ant-layout-content {
    overflow: auto;
  }

  .breadcrumb {
    padding: 8px 0px;
    font-size: 12px;
    padding-left: 24px;
    color: #000;
    background-color: #f7f7f7;
    user-select: none;
  }

  .myFormTable {

    .ant-spin {
      max-height: none !important;
    }

    .myLoadingIcon {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
    }

    .ant-table-body {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
          background-color: #a8a8a8;
        }

        &:active {
          background-color: #787878;
        }
      }

      & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
      }
    }

    // .ant-table-body{
    //   border-bottom-left-radius: 10px;
    //   border-bottom-right-radius: 10px;
    //   border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
    // }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th {
      border-right: 1px solid rgba(0, 104, 178, 0.1000);
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
      border-top: 1px solid rgba(0, 104, 178, 0.1000);
    }

    .ant-table.ant-table-bordered>.ant-table-container {
      border-left: 1px solid rgba(0, 104, 178, 0.1000);
    }

    .ant-table-container {
      border-radius: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }

    .ant-table table {
      border-radius: 10px 10px 0 0;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
      border-top-left-radius: 10px;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
      border-top-right-radius: 10px;
    }

    .ant-table-tbody tr:last-of-type td {
      border-bottom: none;
    }

    .ant-table-tbody tr:last-of-type td:first-child {
      border-bottom-left-radius: 10px;
    }

    .ant-table-tbody tr:last-of-type td:last-child {
      border-bottom-right-radius: 10px;
    }


    .ant-table-thead>tr>th {
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #535353;
      background: rgb(242, 246, 250);
      border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
    }

    .ant-table-tbody>tr>td {
      font-size: 14px;
      font-family: PingFang SC-中等, PingFang SC;
      font-weight: normal;
      color: #535353;
      border-bottom: 1px solid rgba(0, 104, 178, 0.1000);
    }

    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th {
      padding: 16px;
    }

    .noPadding {
      padding: 0px !important;
    }

    .ant-table-cell:hover {
      background: rgb(242, 246, 250) !important;
      cursor: pointer;
      // user-select: none;
    }

    .ant-table-cell-row-hover {
      background: rgb(242, 246, 250) !important;
      cursor: pointer;
      // user-select: none;
    }

    .ant-typography p,
    div.ant-typography {
      margin-bottom: 0;
    }

    .ant-table-body {
      overflow-y: auto !important;
    }

    .ant-table-header {
      border-right: 1px solid rgba(0, 104, 178, 0.1);
      border-radius: 10px 10px 0 0
    }

  }
}

.moreSelectTooltip {
  .ant-tooltip-inner {
    padding: 0;
  }

  .moreSelectTooltipDiv {
    max-height: 300px;
    overflow-y: auto;
    padding: 6px 8px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #eee;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 4px;

      &:hover {
        background-color: #a8a8a8;
      }

      &:active {
        background-color: #787878;
      }
    }

    & {
      scrollbar-width: thin;
      scrollbar-color: #c1c1c1 #eee;
    }

    span {
      display: flex;
      min-height: 21px;
      align-items: center;
      justify-content: center;
      flex: none;
      background: rgba(108, 174, 255, 0.2);
      border-radius: 20px;
      opacity: 1;
      border: 1px solid #6CAEFF;
      padding: 2px 10px;
      font-size: 12px;
      color: #6CAEFF;
      margin-bottom: 5px;

    }
  }
}

.verLabelBox {
  .verNo {
    color: #a8a8a8;
  }
}