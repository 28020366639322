.editHtmlContent {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff;
    padding: 20px;

    .itemTitle {
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        margin-bottom: 16px;

        span {
            display: block;
            width: 2px;
            height: 14px;
            background: linear-gradient(223deg, #006BB3 0%, #15D2D6 100%);
            margin-right: 8px;
        }
    }

    .itemTitle2 {
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 14px;
        padding: 0 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        margin-bottom: 16px;

        span {
            font-size: 20px;
            font-weight: bold;
            color: #0068B2;
            margin-right: 10px;
            position: relative;

            i {
                position: absolute;
                bottom: 0px;
                left: 18px;
                display: block;
                width: 40%;
                height: 70%;
                background: #fff;
                border-left: #787878 1px solid;
                transform: rotate(45deg);
            }

        }

    }

    .subscriptionItemsContent {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 10px;

        .subscriptionItem {
            display: flex;
            align-items: center;
            width: 50%;
            margin-bottom: 16px;
            height: 32px;
            color: #313131;

            i {
                display: flex;
                height: 100%;
                padding: 0 2px;
                align-items: center;
                justify-content: center;
            }

            // i::after {
            //     content: '*';
            //     color: #FE545F;
            //     height: 12px;
            // }

            .itemLabel {
                display: inline-block;
                width: 60px;
                margin-right: 10px;
                text-align: justify;
                text-align-last: justify;
            }
        }
    }

    .subscriptionLicenseItemsContent {
        width: 100%;
        padding: 0 10px;

        .subscriptionLicenseItemsHeader {
            display: flex;
            width: 100%;
            height: 62px;
            align-items: center;
            background: rgba(0, 104, 178, 0.05);

            .headerItem {
                height: 100%;
                display: flex;
                align-items: center;
                // justify-content: center;
                padding: 0 8px;
                color: #535353;

                i {
                    display: flex;
                    height: 100%;
                    padding: 0 2px;
                    align-items: center;
                    justify-content: center;
                    color: #FE545F;
                }
            }
        }
    }

    .tagDiv{
        width: 40px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: #FE545F 1px solid;
        color: #FE545F;
        font-size: 12px;
    }
}