.subscriptionManagementContent {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ffffff;
    padding: 20px;

    .filterItemsBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .filterItemsContent {
            display: flex;
            align-items: center;

            .filterItem {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;

                >span {
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #313131;
                    margin-right: 12px;
                }
            }
        }
    }

    .tableContent {
        margin-top: 24px;

        .selectItem {
            td {
                background: #f2f6fa !important;
            }
        }

        .tableTagsBox {
            width: 100%;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            svg {
                margin-right: 4px;
                width: 14px;
                height: 14px;
                flex: none;
            }

            svg:nth-last-of-type(1) {
                margin-right: 0px;
            }
        }
    }

    .ant-select-multiple {
        .ant-select-selection-overflow::before {
            content: '首页';
            color: black;
            line-height: 28px;
            font-size: 14px;
        }

        .ant-select-selection-item-content {
            overflow: unset !important;
        }

        .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            font-size: 12px;
            font-family: Arial, Arial;
            color: #0068B2;
            border: #0068B2 1px solid;
            background: #ffffff;
        }
    }

}

.PersonAndCompanySelectItem {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .ant-select-item-option-content {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .nameBox {
        width: calc(100% - 66px);
        margin-right: 6px;
        white-space: nowrap; //不支持换行
        overflow: hidden; //隐藏多出部分文字
        text-overflow: ellipsis;
        color: #313131;
    }

    .codeBox {
        display: inline-block;
        width: 60px;
        height: 22px;
        line-height: 22px;
        font-size: 12px;
        text-align: right;
        color: #313131;
        white-space: nowrap; //不支持换行
        overflow: hidden; //隐藏多出部分文字
        text-overflow: ellipsis;
    }

}

.createBusinessDrawer {
    border-radius: 10px 0px 0px 10px;

    .ant-drawer-body {
        // padding-bottom: 5px;
        padding: 20px;
    }

    .drawerFooterBtnBox {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .ant-btn {
            min-width: 90px;
            margin-left: 10px;
            // color: #fff;
            // background: #016DB4;
            // border-color: #0068B2;
        }

        .noBg {
            // color: #535353;
            // background: #FFFFFF;
            // border-color: #D9D9D9;
        }
    }
}

.userSelectItem{
    .name{
        font-weight: bold;
    }
    .labelBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label{
            display: inline-block;
            width: 50%;
            font-weight: 400;
            font-size: 12px;
            color: #535353;
        }
    }
}