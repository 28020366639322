.sidebarContainer {
  height: 100%;

  .my-layout {
    height: 100%;
  }

  .logo {
    position: relative;
    float: left;
    width: 100%;
    height: 44px;
    background-size: 50% 32px;
    display: flex;
    align-items: center;
    // justify-content: center;
  }

  .logo .logoTitle {
    // height: 20px;
    line-height: 20px;
    margin-left: 24px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    // justify-content: center;
    // padding: 0 24px;

    img {
      display: block;
      height: 30px;
      width: auto;
      margin-right: 10px;
    }

    span {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .logo .anticon-menu-unfold {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 5%;
    font-size: 18px;
    // color: #fff;
  }

  .logo .anticon-menu-fold {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    // color: #fff;
  }

  .ant-menu {
    background-color: transparent;
  }

  .ant-menu-item {
    // line-height: 16px;
  }

  .ant-menu-inline {
    border: none;
  }

  .ant-menu svg {
    fill: #fff;
    font-size: 16px;
    height: 16px;
    width: 16px;
  }

  .ant-menu-title-content {
    color: #fff;
    font-size: 16px;
  }

  .ant-menu-title-content a {
    color: #fff;
    font-size: 16px;
  }

  .ant-menu-submenu-title i {
    color: #fff;
    font-size: 16px;
  }

  .anticon-menu-fold {
    font-size: 18px;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.4);
  }

  .ant-menu-item a:hover {
    color: #fff;
  }

  .ant-menu-item-active {
    background: rgba(255, 255, 255, 0.4) !important;
    color: #fff !important;
  }

}